@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Inter', sans-serif;
}


@layer base {
  h1 {
    font-weight: 700;
    text-align: left;
    font-size: 36px;
    line-height: 49px;
    @apply font-bold;

    
    @screen sm {
      font-size: 56px !important;
      line-height: 74px !important;
    }

    @screen lg {
      font-size: 76px !important;
      line-height: 100px !important;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 36px;
    text-align: left;
    line-height: 49px;

    @screen sm {
      font-size: 50px;
      line-height: 64px;
    }

    @screen lg {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: 0.02em;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    text-align: left;

    @screen sm {
      font-size: 50px;
      line-height: 61px;
    }

    @screen lg {
      font-size: 64px;
      line-height: 72px;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    text-align: left;

    @screen sm {
      font-size: 61px;
      line-height: 72px;
    }

    @screen lg {
      font-size: 80px;
      line-height: 96px;
    }
  }

  /* h5 {
    font-weight: 700;
    text-align: left;

    @screen sm {
      font-size: 42px;
      line-height: 51px;
      text-align: center;
    }

    @screen lg {
      font-size: 80px;
      line-height: 96px;
    }
  } */

  h6 {
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    line-height: 35px;
    color: #071C35;
    
    @screen sm {
      font-size: 20px;
      line-height: 40px;
    }

    @screen lg {
      font-size: 24px;
      line-height: 46px;
    }
  }

  p {
    font-family: 'Be Vietnam', sans-serif;
    font-weight: 400;
    text-align: left;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.5%;
    
    @screen sm {
      font-size: 18px;
      line-height: 30px;
    }

    @screen lg {
      font-size: 20px;
      line-height: 32px;
    }
  }
}

.paraTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: #1961FD;
  
  @screen sm {
    font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  text-align: left;
  color: #1961FD;
  }

  @screen md {
    font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  text-align: left;
  color: #1961FD;
  }
}

.paraSubTitle{
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: left;
  color: #071C35;
  
  @screen sm {
    font-Size: 20px !important;
    line-height: 30px;
  }

  @screen md {
    font-Size: 22px !important;
    line-height: 39px;
  }
}

.paraText {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: left;
  color: #071C35;
  
  @screen sm {
    
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  color: #071C35;
  
  }

  @screen md {
    
  font-weight: 400;
  font-size: 22px;
  line-height: 39px;
  text-align: left;
  color: #071C35;
  
  }
}
